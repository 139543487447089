import BaseBean from "@/utils/BaseBean";

export interface IZoneCardDataObj {
    utilInst:ZoneCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class ZoneCardUtil extends BaseBean{
    public dataObj:IZoneCardDataObj;

    constructor(proxy:any,dataObj:IZoneCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});
        this.dataObj.otherParams.zoneTreeData=await this.utils.OrderProviderApi.getZoneTreeData({params:{type:1}});
    }
}